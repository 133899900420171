
import { defineComponent } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import { Form, Field } from 'vee-validate';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import CertificateEditModal from '@/layout/header/partials/trainee/certificateDrawer.vue';
import { VueCookieNext } from 'vue-cookie-next';
import QrcodeVue from 'qrcode.vue';

export default defineComponent({
    mixins: [apiEndpoint],
    name: 'training-certificate-configuration',
    components: {
          Form,
        Field,
        Datatable,
        QrcodeVue,
        CertificateEditModal
    },
    data() {
        return {
            batch: {
                entity_id: '',
            },
            api_url: '',
            file: '' as any,
            left: '' as any,
            middle: '' as any,
            right: '' as any,
            entityInfos: {
                entity_name: '',
                entity_short_name: '',
                image_file_name: '',
            } as any,
            BackgroundInfos: {
                left_signatory: '',
                middle_signatory: '',
                right_signatory: '',

            } as any,
            Infos: {
                id: '',
                entity_id: '',
                left_signatory: '',
                middle_signatory: '',
                right_signatory: '',
                active_status: '',
                background_image: ''
            } as any,


            actionActive: false,
            entity_id : '' as any,
            actionDelete: false,
            background_image: "" as any,
            instituteList: [],
            courseList: [],
            checkedData: [],
            traineeList: [] as any,
            batchList: [],
            tranches_info: [],
            tableHeader: [
                  {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                    width: '300px',
                },
                {
                    name: 'SL',
                    key: 'sl',
                    sortable: true,
                },
                 {
                    name: 'Training Partner',
                    key: 'entity',
                    sortable: true,
                },
                {
                    name: 'Left Signature',
                    key: 'photo',
                    sortable: true,
                },
                {
                    name: 'Right Signature',
                    key: 'photonext',
                    sortable: true,
                },
                {
                    name: 'Left Signatory',
                    key: 'left',
                    sortable: true,
                },
                {
                    name: 'Right Signatory',
                    key: 'right',
                    sortable: true,
                },

            ],
            showDataTable: false,
            showTable: false,
            componentKey: 0,
            loading: false,
            load: false,
            qrData: '' as any,
            courseInfoData: false,
            showCourseNotice: false,
            certificationdate: '' as any,

        };

    },
    async created() {
        this.api_url = this.VUE_APP_API_URL;
        await this.getEntityInfos();
        await this.allTemplate();
        this.emitter.on('certificate-updated', async () => {
            this.allTemplate();
        });
    },
    methods: {

        onChange(event) {
            console.log();
            let filename = event.target.files[0]?.name;

            this.file = [];
            this.file = event.target.files ? event.target.files[0] : null;

        },
        async formSubmit() {

            let formdata = new FormData();
            formdata.set('entity_id', this.batch.entity_id);
            formdata.set('image', this.file);
            formdata.set('left', this.left);
            formdata.set('middle', this.middle);
            formdata.set('right', this.right);
            ApiService.post(this.VUE_APP_CERTIFICATION_CONFIG_API, formdata)
                .then((response) => {
                    this.load = false;

                    if (response?.status == 200) {
                        if (response.data.status == 'success') {
                            Swal.fire({
                                title: 'Saved Successfully!',
                                text: response.data.data,
                                icon: 'success',
                                buttonsStyling: false,
                                confirmButtonText: 'Preview Certificate',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.preview();
                                    this.BackgroundImage();
                                }
                            });
                        }
                        if (response.data.status == 'error') {
                            Swal.fire({
                                title: 'Error!',
                                text: response.data.message,
                                icon: 'error',
                                buttonsStyling: false,
                                confirmButtonText: 'Close',
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                            });
                        }
                    } else {
                        let err = '';
                        for (const field of Object.keys(response.data.errors)) {
                            err += response.data.errors[field][0] + '<br>';
                        }
                        Swal.fire({
                            title: response.data.errors,
                            html: err,
                            icon: 'error',
                            buttonsStyling: false,
                            confirmButtonText: 'Close',
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                        });
                    }
                })
                .catch((response) => {
                    this.load = false;
                    console.log(response);
                });

        },
        async preview() {
            this.loading = true;
           
            await ApiService.get("assessment/certificate/getlogo?entity=" + this.entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.entityInfos = response.data.data;

                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
        },

        Inactive(id) {
            let user_id = VueCookieNext.getCookie('_seip_user');
            Swal.fire({
                title: 'Are you sure you want to inactive it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Inactive!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.get(
                        'assessment/certificate/status-change?id=' +
                        id +
                        '&active_status=0' +
                        '&responsible_user_id=' +
                        user_id.id
                    )
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Inactivated!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);
                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },
        Active(id) {
            let user_id = VueCookieNext.getCookie('_seip_user');
            Swal.fire({
                title: 'Are you sure you want to Activate it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Activate!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.get(
                        'assessment/certificate/status-change?id=' +
                        id +
                        '&active_status=1' +
                        '&responsible_user_id=' +
                        user_id.id
                    )
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Activated!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);

                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },


        async view(id) {
            this.loading = true;
            await ApiService.get("assessment/certificate/single?id=" + id
            )
                .then((response) => {
                    this.loading = false;
                    console.log(response.data.data);
                    this.BackgroundInfos = response.data.data;
                    this.entity_id = response.data.data[0].entity_info_id;
                    this.preview();
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
                
            this.showDataTable = true;
            this.showTable = true;
            

        },

        Delete(id) {
            Swal.fire({
                title: 'Are you sure you want to delete it?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete!',
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete('assessment/certificate/delete?id=' + `${id}`)
                        .then((response) => {
                            if (response.data.status == 'error') {
                                this.load = false;
                                Swal.fire({
                                    title: 'Error!',
                                    html: response.data.message,
                                    icon: 'error',
                                    buttonsStyling: false,
                                    confirmButtonText: 'Close',
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                });
                            } else {
                                Swal.fire('Deleted!', response.data.data, 'success').then(
                                    () => {
                                        this.showTable = true;
                                        this.emitter.emit('certificate-updated', true);


                                    }
                                );
                            }
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },

         edit(data) {
            this.emitter.emit('certificate-edit', data);
        },

        async BackgroundImage() {
            this.loading = true;
            let formdata = new FormData();
            formdata.set('entity_id', this.batch.entity_id);
            await ApiService.get("assessment/certificate/background?entity=" + this.batch.entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.BackgroundInfos = response.data.data;
                    this.qrData = 'https://bee-seip-local.tappware.com/certificate/training-certificate-view/';
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });

            this.showTable = true;
        },

        async allTemplate() {

            this.loading = true;
            let entity_id = '';
            await ApiService.get("assessment/certificate/allbackgroundactive?entity=" + entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.Infos = response.data.data;
                    this.showTable = true;
                    this.componentKey += 1;
                    this.preview();
                    this.BackgroundImage();
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });

        },

        async getEntityInfos() {
            this.loading = true;
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            await ApiService.get(
                this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
            )
                .then((response) => {
                    this.loading = false;
                    this.entityInfos = response.data.data;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    console.log(response);
                });
        },
    },
    setup() {
        const AssociationSchema = Yup.object().shape({
            //entity_name: Yup.string().required().label("Name"),
            //entity_short_name: Yup.string().required().label("Short Name"),
        });
        return {
            AssociationSchema,
        };
    },
});
